.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.app:has(.deckBuilder) {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

main {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

p {
  font-family: ZagBold, sans-serif;
  text-shadow: 1px 2px 6px rgba(0, 0, 0, .3);
  line-height: 0;
  padding-top: 1.2vmin;
  padding-left: 1.2vmin;
}

.background-control {
  width: 100vmin;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.game {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  color: white;
  flex: 2 0 auto;
}

.corporation {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-shadow: 2px 3px 8px rgba(0, 0, 0, .8);
  font-size: 2.4vmin;
  font-weight: bold;
  color: white;
  /* padding-bottom: 2vmin;; */
  padding-bottom: 5.5vmin;
}

.icon-img {
  width: 5vmin !important;
  height: 5vmin !important;
}

.icon-img.icon-img-renomear {
  width: 4vmin !important;
  height: 4vmin !important;
}

.icon-img.icon-img-straight {
  margin-top: 0.5vmin !important;
  width: 1vmin !important;
  height: auto !important;
}

.editable {
  cursor: pointer;
}

.text-title {
  word-wrap: break-word;
  text-align: center;
  max-width: 41.5vmin;
}

.text-selected {
  font-weight: bold;
  color: var(--cor-thema);
  text-shadow: 1px 0px var(--cor-thema);
}

.image-selected {
  filter: var(--filter-tema);
}

.text-danger {
  color: red !important;
}

img.text-danger {
  filter: sepia(1) saturate(10000%) hue-rotate(290deg) saturate(700%) !important;
}

.bg-danger {
  background-color: red !important;
}

.bt-deck-editName {
  filter: var(--filter-tema);
}

.text-underline {
  text-decoration: underline;
}

.margin-top-1 {
  margin-top: 1vmin !important;
}

/* .margin-top-2 {
  margin-top: 3.55vmin !important;
} */

.margin-top-3 {
  margin-top: 5vmin !important;
}

.margin-top-minus-1 {
  margin-top: -1vmin !important;
}

.margin-top-minus-2 {
  margin-top: -3.75vmin !important;
}

.margin-top-minus-3 {
  margin-top: -4.75vmin !important;
}

.margin-top-minus-4 {
  margin-top: -6.6vmin !important;
}

.margin-bottom-1 {
  margin-bottom: 1vmin !important;
}

.margin-bottom-2 {
  margin-bottom: 3.55vmin !important;
}

.margin-bottom-3 {
  margin-bottom: 5vmin !important;
}

.padding-top-2 {
  padding-top: 3.55vmin !important;
}

.padding-top-3 {
  padding-top: 5vmin !important;
}

.padding-bottom-1 {
  padding-bottom: 1vmin !important;
}

.padding-bottom-2 {
  padding-bottom: 3.55vmin !important;
}

.padding-bottom-3 {
  padding-bottom: 5vmin !important;
}

.padding-left-1 {
  padding-left: 1vmin !important;
}

.padding-right-1 {
  padding-right: 1vmin !important;
}

.nav-tab {
  display: flex;
}

.nav-tab button {
  background-color: #00000087;
  color: var(--cor-texto-tema);
  /* box-shadow: none; */
  width: -webkit-fill-available;
  height: 5vmin;
  font-size: 12pt;
  /* border-radius: 1vmin;
  margin: 0 1vmin; */
}

@keyframes zoomin7G {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomin {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2) translate3d(4vw, 2vh, 0px);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes breath {
  0% {
    /* background-position: right; */
    background-size: cover;
  }

  50% {
    /* background-position: top; */
    background-size: 170vmax;
  }

  100% {
    /* background-position: right; */
    background-size: 100vmax;
  }
}

/** dimensões máximas esperadas **/

@media screen and (min-width: 500px) And (min-height: 891px) {

  /* .background-control {
    width: 40px;
  } */
  p {
    padding-top: 6px;
    padding-left: 6px;
  }

  .corporation {
    font-size: 12px;
    padding-bottom: 23px;
  }



}