.deckBuilder {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  color: white;
  flex: 2 0 auto;

  width: 100vw;
  /* max-width: 100vw;
  display: block; */
}

.deckBuilder-header {
  margin: 3vmin 5vmin 0 5vmin;
  width: 92vmin;
}

.deckBuilder-header-block {
  display: flex;
  justify-content: space-between;
  padding: 0 2vmin 0 2vmin;
}

.deckBuilder-header-block .title {
  font-family: 'Open Sans', sans-serif;
  /* width: 60vmin; */
  text-align: left;
  font-size: 3.5vmin;
}

.deckBuilder-header-block .header-options {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.deckBuilder-header-block .bt-option {
  margin: 1vmin 0vmin 1vmin 3vmin;
}

.deckBuilder-header-block .bt-option.bt-option-invert {
  background-color: transparent;
}

/* .deckBuilder-header-block .bt-option img {
  filter: var(--filter-icone-tema);
} */

.deckBuilder-header-block .bt-option.bt-option-invert img {
  height: 6.5vmin;
  width: 6.5vmin;
}

.header-options .action {
  height: 80%;
  font-weight: 700;
  font-size: 3.5vmin;
  min-width: 22vmin;
  padding: 0 3vmin;
}


.header-options .limpar {
  background-color: unset;
  box-shadow: unset;
  border: unset;
  background-image: unset;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 3.5vmin;
  min-width: 22vmin;
  padding: 0 3vmin;
}

/* .deckBuilder-header-block .deckBuilder-cards-results,
.deckBuilder-header-block .deckBuilder-decks-results {
  width: -webkit-fill-available;
} */

/* .deckBuilder-header-block .deckBuilder-cards-results-info,
.deckBuilder-header-block .deckBuilder-decks-results-info {} */

.deckBuilder-cards-back-deck {
  padding: 1px 10px 3px 10px;
  font-weight: 600;
  font-size: 2.5vmin;
  line-height: 3.5vmin;
}

.deckBuilder-cards-results-info {
  font-weight: 600;
}

.deckBuilder-cards-results-label {
  font-size: 3vmin;
}

.deckBuilder-header .bt-deckBuilder-search {
  margin: 3vmin 0 0 0;
}

.deckBuilder-cards-categories {
  margin-top: 2vmin;
  display: flex;
  justify-content: space-around;
}

/* .deckBuilder-cards-categories-item {
  margin: 0vmin 3vmin;
}

.deckBuilder-cards-categories-item:first-child {
  margin-left: 6vmin;
} */

.deckBuilder-body-filters-container {
  display: flex;
  flex-direction: column;
  row-gap: 2.5vmin;
}


.deckBuilder-filter-container {
  background-color: rgba(0, 0, 0, .6);
  border: 1px solid #4a4a4a;
  border-radius: 1.5vmin;
  padding: 3.5vmin;
}

.deckBuilder-filter-title {
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
}

.deckBuilder-filter-items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 2vmin;
  padding-block-start: 3vmin;
}

.deckBuilder-filter-item {
  display: flex;
  flex-basis: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .6);
  border: 1px solid #4a4a4a;
  border-radius: 1.5vmin;
  height: 8vmin;
  font-size: 2.5vmin;

}


.deckBuilder-filter-item.model-1 {
  min-width: 13.1vmin;
}

.deckBuilder-filter-item.model-2, .deckBuilder-filter-item.model-3, .deckBuilder-filter-item.model-4, .deckBuilder-filter-item.model-5 {
  min-width: 28.2vmin;
}

.deckBuilder-filter-item.model-3 {
  font-size: 2.5vmin;
}

.deckBuilder-filter-item.model-2 img, .deckBuilder-filter-item.model-3 img {
  width: 6vmin;
  margin-right: 0.5vmin;
}

.deckBuilder-filter-item.model-4 img {
  width: 4vmin;
  margin-right: 1.5vmin;
}

.deckBuilder-filter-item.model-5 img {
  width: 4.5vmin;
  margin-right: 1.5vmin;
}

.deckBuilder-cards-categories-item.category-selected {
  animation: dropshadow 0.08s linear;
  border-bottom: 0.5vmin solid var(--cor-thema);
  border-radius: 0vmin;
}

.deckBuilder-cards-categories-item {
  border-bottom: 0.5vmin solid transparent;
}

.deckBuilder-cards-categories-item .count {
  font-weight: 600;
  font-size: 4.5vmin;
}

.deckBuilder-cards-categories-item .title {
  /* font-weight: bold; */
  margin-left: 0.35vmin;
  font-size: 3vmin;
}

.deckBuilder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95vh;
}

.deckBuilder-body {
  overflow: auto;
  /* margin: 3vmin 0vmin 0vmin 3vmin; */
  margin: 3vmin 0vmin 0vmin 0vmin;
  width: 97vw;
}

/* 
.deckBuilder-body.deckList-body {
  max-height: var(--max-height-content-deckList);
} */

/* .deckBuilder-body.has-shadow {
  box-shadow: inset 0px 12px 16px -17px black;
} */


.deckBuilder-body-cards-container {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; */
  display: grid;
  margin: 0 0 20vmin 1.8vmin;
  grid-template-columns: repeat(auto-fill, minmax(29.5vmin, 1fr));
  grid-row-gap: 2.5vmin;
}

.deckBuilder-body-cards-container.mini {
  grid-template-columns: repeat(auto-fill, minmax(6vh, 1fr));
  grid-row-gap: 1.5vmin;
  grid-column-gap: 1vmin;
  margin: 0 0 10vmin 1.2vmin;
}

.deckBuilder-body-cards-container.small {
  grid-template-columns: repeat(auto-fill, minmax(29.5vmin, 1fr));
}

.deckBuilder-body-cards-container.medium {
  grid-template-columns: repeat(auto-fill, minmax(45vmin, 1fr));
}

.deckBuilder-body-decks-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 0 3vmin 1.8vmin;
  grid-gap: 4vmin;
}

.bt-deckBuilder {
  display: flex;
  align-items: center;
  cursor: pointer;

}

.bt-deckBuilder img {
  pointer-events: none;

}

.bt-deckBuilder .icon-minus-plus {
  width: 5vmin;
  height: 5vmin;
}

.deckBuilder-card-thumb {
  /* animation: fadein .6s linear; */
  display: flex;
  width: 29.5vmin;

}

.deckBuilder-card-thumb.mini {
  width: 6.1vh !important;
  box-shadow: .3vmin .3vmin .4vmin rgba(0, 0, 0, 0.4);
  border-radius: 0.5vmin;
}

.deckBuilder-card-thumb.small {
  width: 29.5vmin !important;
  box-shadow: .5vmin .5vmin .7vmin rgba(0, 0, 0, 0.4);
  border-radius: 1.5vmin;
}

.deckBuilder-card-thumb.medium {
  width: 45vmin !important;
  /* filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, .5)); */
  box-shadow: .5vmin .5vmin .9vmin rgba(0, 0, 0, 0.4);
  border-radius: 2.1vmin;
}


.deckBuilder-deck-thumb {
  display: grid;
  width: 44.5vmin;
  height: 44.5vmin;
  border-radius: 2vmin;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  background-color: rgba(196, 194, 194, 0.62);
  grid-gap: 1.2px 1px;
}

/* Grid para 4 elementos */
.grid4 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

/* Grid para 3 elementos */
.grid3 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
}

/* Grid para 2 elementos */
.grid2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

/* Grid para 1 elemento */
.grid1 {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.bg-deck-stroj {
  width: 100%;
  background-image: url(../images/decks/bg-deck-stroj.png);
  background-size: cover;
}

.bg-deck-gaia {
  width: 100%;
  background-image: url(../images/decks/bg-deck-gaia.png);
  background-size: cover;
}

.bg-deck-adroit {
  width: 100%;
  background-image: url(../images/decks/bg-deck-adroit.png);
  background-size: cover;
}

.bg-deck-majik {
  width: 100%;
  background-image: url(../images/decks/bg-deck-majik.png);
  background-size: cover;

}

.bg-deck-7g {
  width: 100%;
  background-image: url(../images/decks/bg-deck-7g.png);
  background-size: cover;
}

.grid2>div:nth-child(1) {
  background-position-x: left !important;
}

.grid2>div:nth-child(2) {
  background-position-x: right !important;
}

.grid3>div:nth-child(1) {
  background-position-x: left !important;
}

.grid3>div:nth-child(2) {
  background-position-x: center !important;
}

.grid3>div:nth-child(3) {
  background-position-x: right !important;
}

.grid4>div:nth-child(1) {
  /* background-size: 60vmin !important;
  background-position: 25% 25% !important; */

  background-size: 45vmin !important;
  background-position: 0% 5% !important;
}

.grid4>div:nth-child(2) {
  /* background-size: 60vmin !important;
  background-position: 75% 25% !important; */

  background-size: 45vmin !important;
  background-position: 100% 5% !important;
}

.grid4>div:nth-child(3) {
  /* background-size: 60vmin !important;
  background-position: 25% 75% !important; */

  background-size: 45vmin !important;
  background-position: 0% 95% !important;
}

.grid4>div:nth-child(4) {
  /* background-size: 60vmin !important;
  background-position: 75% 75% !important; */

  background-size: 45vmin !important;
  background-position: 100% 95% !important;
}

.grid3 img, .grid2 img {
  max-width: 150%;
  width: auto !important;
}

.deckBuilder-deck-galaxy {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.deckBuilder-deck-galaxy img {
  width: 100%;
}

/* ,.bt-deckBuilder-search input[type='text']  */
.deckBuilder-deck-nameBox {
  width: 100%;
  background-color: rgba(0, 0, 0, .6);
  border-bottom-left-radius: 3vmin;
  border-bottom-right-radius: 3vmin;
  min-height: 8.5vmin;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 1vmin 2vmin;

}

.deckBuilder-deck-nameBox b {
  cursor: pointer;
  font-size: 3vmin;
}

.deckBuilder-deck-nameBox input {
  width: 30vmin;
}


.deckBuilder-item-nameBox {
  width: 95%;
  display: flex;
  flex-basis: 0;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, .7);
  border: 1px solid #4a4a4a;
  border-radius: 1.5vmin;
  height: 7vmin;
  font-weight: 600;
  font-size: 4.5vmin;
  margin-block-start: 1vmin;

}

.deckBuilder-alert-message {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vh;
}

/* .deckBuilder-span-cardAmount {
  display: inline-flex;
  margin: auto;
} */


.bt-deckBuilder-search {
  cursor: text;
  pointer-events: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, .6);
  border: 1px solid #4a4a4a;
  color: var(--cor-texto-tema);
  font-size: 3vmin;
  border-radius: 8vmin;
  height: 9vmin;
}

.bt-deckBuilder-search input[type='text'] {
  height: 9vmin;
  width: 92%;
  padding-left: 4vmin;
  font-size: 3vmin;
  background-color: transparent;
  border: none;
  color: var(--cor-texto-tema);
}

.bt-deckBuilder-search img {
  right: 9vmin;
  height: 4vmin;
  width: 4vmin;
}

.deckBuilder-galaxiesContainer {
  display: flex;
  margin: 3vmin 0 0 0;
  justify-content: space-between;
}

.bt-galaxy {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .6);
  height: 10vmin;
  width: 10vmin;
  border: 1px solid #4a4a4a;
  border-radius: 100%;
}


.bt-galaxy.galaxy-selected,
.deckBuilder-filter-item.filter-selected {
  animation: dropshadow 0.08s linear;
  color: var(--cor-texto-tema);
  border: solid 0.5vmin var(--cor-thema);
}

.bt-galaxy img {
  height: 7vmin;
  width: 7vmin;
}

/* .img-raridade {
  width: 4.5vmin !important;
  margin: 1.75vmin 1.5vmin 0 0 !important;
} */

.img-triangle {
  margin-bottom: 1vmin !important;
}

.bt-deck-editName {
  cursor: pointer;
  float: right;
  padding: 0 3vmin !important;
}

.deckBuilder-footer {
  position: fixed;
  bottom: 0;
  /* width: -webkit-fill-available; */
  height: 15vmin;
  /* box-shadow: inset 0px -51px 34px -19px black; */
}

.deckBuilder-footer-item {
  display: inline-flex;
  margin: 2vmin 4vmin;
}

.deckBuilder-footer-itemBox {
  display: grid;
}

.deckBuilder-footer-itemBox img {
  margin: auto;
  height: 5vmin;
}

.deckBuilder-footer-itemBox span {
  margin: auto;
}

.deckBuilder-edit-block {
  margin-top: 1vmin;
  background-color: #00000080;
  border-radius: 5vmin;
}

@media screen and (min-width: 500px) {
  .deckBuilder {
    row-gap: 4.62px;
  }

  .bt-deckBuilder {
    margin-bottom: 1vmin;
  }

  .bt-deckBuilder-search input[type='text'] {
    top: -2vmin;
    /* width: -webkit-fill-available; */
  }

  .bt-deck-editName {
    /* position: relative !important; */
    height: 5vmin !important;
    top: 0.5vmin !important;
    /* right: 1vmin; */
  }
}