.modal-transparent {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.33);
}

.modal-transparent-body {
  /* position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row; */
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}



.modal-transparent-body.floating {
  top: 9vh;
}

.modal-transparent-content {
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  max-width: 30vh;
  max-height: 75vh;
  /* height: 90vmin; */
  font-size: 10.5pt;
  padding: 1.7em 1.7em 1.6em 1em;
  border-radius: 2vmin 0 0 2vmin;
  border: 1px solid #4a4a4a;
  border-right: none;
  overflow: auto;
}

.modal-transparent-content.no-padding-top, .modal-transparent-content.floating {
  padding-top: 0.5em !important;
}

.modal-transparent-title {
  display: block;
  padding: 0.75em 0em 0.35em 0em;
  color: white;
}

.modal-transparent-list {
  color: white;
}

.modal-transparent-listItem {
  color: white;
  display: flex;
  align-items: center;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
}

.modal-transparent-listItem input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.modal-transparent-listItem .checkmark {
  display: flex;
  background-color: transparent;
  gap: 0.5em;
  margin: 0.20em 0 0 0;
  height: 1em;
  width: 1em;
  border: 1px solid white;
  border-radius: 50%;
}

.modal-transparent-listItem .inputLabel {
  margin: 0.3em 0.5em;
}

.modal-transparent-listItem:hover input~.checkmark {
  background-color: rgba(255, 255, 255, 0.33);
}

.modal-transparent-listItem img {
  height: 5.5vmin;
  width: 5.5vmin;
  margin: 1vmin 2vmin;
}

.modal-transparent-list ul li {
  list-style: none;
}

.modal-transparent-list ul li.text-danger:has(strong) strong {
  font-weight: 900;
}

.modal-transparent-list ul li.text-danger:not(:has(strong)) {
  list-style: inside;
  font-weight: 600;
}

.modal-transparent-list ul li:last-child {
  margin-bottom: 1em;
}


.modal-transparent-carousel {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.93);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 0.1s linear;
  color: #fff;
  user-select: none;
}

.modal-transparent-carousel p {
  font-family: 'Open Sans', sans-serif;
  line-height: normal;
  padding-top: 1.6vmin;
}

.modal-transparent-carousel-container {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 5vh;
  height: 90vh;
  animation: fadein 0.4s linear;
}

.modal-transparent-carousel-body {
  width: 90vmin;
}

.modal-transparent-carousel-body-header {
  padding: 0 0 6vmin 0;
  width: 44vh;
  max-width: 86vw;
}

.modal-transparent-carousel-title,
.modal-transparent-carousel-subtitle {
  font-weight: bold;
  font-size: 5.5vmin;
}

.modal-transparent-carousel-subtitle {
  font-style: italic;
  font-size: 4.5vmin;
}

.modal-transparent-carousel-body-content {
  display: flex;
  align-items: end;
}

.modal-transparent-carousel-body-content .card-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-transparent-carousel-body-content .thumb {
  display: flex;
}

.modal-transparent-carousel-body-content .icon-wrapper {
  display: flex;
  align-items: center;
  width: 50%;
  position: absolute;
}

.modal-transparent-carousel-body-content .thumb, .modal-transparent-carousel-body-content .icon-wrapper {
  height: 124vmin;
  max-height: 62vh;
}

.modal-transparent-carousel-body-content .icon-wrapper.left {
  left: 0;
  justify-content: flex-start;
}

.modal-transparent-carousel-body-content .icon-wrapper.right {
  right: 0;
  justify-content: flex-end;
}

.modal-transparent-carousel-body-content .icon-wrapper img {
  width: 5vmin;
}

.modal-transparent-carousel-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.modal-transparent-carousel-footer-content {
  padding: 0 2vmin;
  align-items: center;
  font-size: 7vmin;
  font-weight: 600;
}

.modal-transparent-carousel-footer-content .deckBuilder-item-nameBox.transparent {
  background-color: transparent;
  border: none;
}

.modal-transparent-carousel-footer-content .bt-deckBuilder {
  padding: 0 5vmin;
}

.modal-transparent-carousel-footer-content .bt-deckBuilder img {
  width: 9vmin !important;
  height: 9vmin !important;
}

.modal-transparent-carousel-footer-content .deckBuilder-span-cardAmount {
  font-size: 8vmin;
}



@media screen and (min-width: 300px) And (max-height: 890px) And (min-aspect-ratio: 9/16) {}

@media screen and (min-width: 300px) And (max-height: 890px) And (min-aspect-ratio: 1/1) {}

@media screen and (min-width: 500px) And (min-height: 891px) {

  .modal-transparent-carousel p {
    padding-top: 1.6vmin;
  }

  .modal-transparent-carousel-body {
    width: 90vmin;
  }

  .modal-transparent-carousel-body-header {
    padding: 0 0 30px 0;
    width: 392.033px;
    max-width: 430px;
  }

  .modal-transparent-carousel-title,
  .modal-transparent-carousel-subtitle {
    font-size: 27.5px;
  }

  .modal-transparent-carousel-subtitle {
    font-size: 22.5px;
  }

  .modal-transparent-carousel-body-content .thumb, .modal-transparent-carousel-body-content .icon-wrapper {
    height: 552.417px;
    max-height: 552.417px;
  }

  .modal-transparent-carousel-body-content .icon-wrapper img {
    width: 25px;
  }

  .modal-transparent-carousel-footer-content {
    padding: 0 10px;
    font-size: 35px;
  }

  .modal-transparent-carousel-footer-content .bt-deckBuilder {
    padding: 0 25px;
  }

  .modal-transparent-carousel-footer-content .bt-deckBuilder img {
    width: 45px !important;
    height: 45px !important;
  }

  .modal-transparent-carousel-footer-content .deckBuilder-span-cardAmount {
    font-size: 40px;
  }

}