.theme {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  color: #fff;
  background-color: rgba(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.theme p {
  font-family: 'Museo Sans', sans-serif;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2vmax;
}

.theme img {
  pointer-events: none;
}

.carregafont {
  font-family: ZagBold;
  font-size: 10px;
  color: rgba(255, 255, 255, 0);
}

.theme-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.theme-block {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
  /* overflow: hidden; */
}

.theme-background {
  width: 100%;
  height: 33.34vh;
  margin-top: -1vmin;
  z-index: -1;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-sevenG {
  background-image: url(./images/Tema_SevenG.jpg);
  animation: fadein 0.5s linear, zoomin7G 50s linear 700ms infinite;
}

.bg-stroj {
  background-image: url(./images/Tema_Stroj.jpg);
  animation: fadein 0.6s linear, zoomin 50s linear 700ms infinite;
}

.bg-gaia {
  background-image: url(./images/Tema_Gaia.jpg);
  animation: fadein 0.7s linear, zoomin 50s linear 700ms infinite;
}

.bg-adroit {
  background-image: url(./images/Tema_Adroit.jpg);
  animation: fadein 0.7s linear, zoomin 50s linear 700ms infinite;
}

.bg-majik {
  background-image: url(./images/Tema_Majik.jpg);
  animation: fadein 0.7s linear, zoomin 50s linear 700ms infinite;
}

.theme-itens {
  position: absolute;
  width: 100vw;
  height: 20vh;
}

.theme-itens#sevenG {
  animation: fadein 0.4s linear;
}

.theme-itens#stroj {
  animation: fadein 0.6s linear;
}

.theme-itens#gaia {
  animation: fadein 0.9s linear;
}

.theme-itens#adroit {
  animation: fadein 1s linear;
}

.theme-itens#majik {
  animation: fadein 1.2s linear;
}

.theme-itens-flex {
  box-shadow: 0px 1vh 1vh 0vh rgba(0, 0, 0, 0.4);
  display: flex;
  height: 99.9%;
  flex-direction: column;
  align-items: center;
}

.theme-top-shadow {
  position: absolute;
  z-index: 2;
  height: 0;
  opacity: 0.5;
  width: 44vmin;
  border-top: 7.3vmin solid rgba(22, 58, 255, 0.94);
  border-left: 4.4vmin solid transparent;
  border-right: 4.4vmin solid transparent;
  filter: blur(0.42vmin);
}

.theme-top {
  position: sticky;
  z-index: 4;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 44vmin;
  border-top: 7vmin solid rgba(0, 0, 0, 1);
  border-left: 5vmin solid transparent;
  border-right: 5vmin solid transparent;
}

.theme-top p {
  font-size: 2.6vmin;
  padding: 0 0 2.2vmin 0vmin;
}

.theme-top-null {
  width: 60vmin;
  height: 6.82vmin;
}

.theme-simbolo#sevenG {
  padding-top: 0.5vmin;
}

.theme-simbolo {
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.theme-simbolo img {
  width: 33vmin;
}

.theme-simbolo#sevenG img {
  width: 29vmin;
}

.theme-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  font-size: 3vmin;
  padding: 0 0 0 2vmin;
  bottom: 2.5vmin;
}

@media screen and (min-width: 300px) And (max-height: 890px) And (min-aspect-ratio: 9/16) {

  .theme p {
    letter-spacing: 0.1115vmax;
  }

  .theme-top-shadow {
    width: 24.51vmax;
    border-top: 4.07525vmax solid rgba(22, 58, 255, 0.94);
    border-left: 2.454vmax solid transparent;
    border-right: 2.454vmax solid transparent;
    filter: blur(0.23415vmax);
  }

  .theme-top {
    width: 24.51vmax;
    border-top: 3.9025vmax solid rgba(0, 0, 0, 1);
    border-left: 2.77vmax solid transparent;
    border-right: 2.77vmax solid transparent;
  }

  .theme-top p {
    font-size: 1.447vmax;
    padding: 0 0 1.2245vmax 0vmax;
  }

  .theme-top-null {
    width: 33.45vmax;
    height: 3.80385vmax;
  }

  .theme-simbolo#sevenG {
    padding-top: 0.2775vmax;
  }

  .theme-simbolo img {
    width: 18.2975vmax;
  }

  .theme-simbolo#sevenG img {
    width: 16.0825vmax;
  }

  .theme-title {
    font-size: 1.665vmax;
    padding: 0 0 0 1.115vmax;
    bottom: 1.3875vmax;
  }


}

@media screen and (min-width: 300px) And (max-height: 890px) And (min-aspect-ratio: 1/1) {

  .theme p {
    letter-spacing: 0.1115vmin;
  }

  .theme-top-shadow {
    width: 24.51vmin;
    border-top: 4.07525vmin solid rgba(22, 58, 255, 0.94);
    border-left: 2.454vmin solid transparent;
    border-right: 2.454vmin solid transparent;
    filter: blur(0.23415vmin);
  }

  .theme-top {
    width: 24.51vmin;
    border-top: 3.9025vmin solid rgba(0, 0, 0, 1);
    border-left: 2.77vmin solid transparent;
    border-right: 2.77vmin solid transparent;
  }

  .theme-top p {
    font-size: 1.447vmin;
    padding: 0 0 1.2245vmin 0vmin;
  }

  .theme-top-null {
    width: 33.45vmin;
    height: 3.80385vmin;
  }

  .theme-simbolo#sevenG {
    padding-top: 0.2775vmin;
  }

  .theme-simbolo img {
    width: 18.2975vmin;
  }

  .theme-simbolo#sevenG img {
    width: 16.0825vmin;
  }

  .theme-title {
    font-size: 1.665vmin;
    padding: 0 0 0 1.115vmin;
    bottom: 1.3875vmin;
  }


}

/** dimensões máximas esperadas **/

@media screen and (min-width: 500px) And (min-height: 891px) {

  .theme p {
    letter-spacing: 0.983333px
  }

  .theme-top-shadow {
    width: 219.55px;
    border-top: 36px solid rgba(22, 58, 255, 0.94);
    border-left: 21px solid transparent;
    border-right: 21px solid transparent;
    filter: blur(2.08333px);
  }

  .theme-top {
    width: 219.55px;
    border-top: 34px solid rgba(0, 0, 0, 1);
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
  }

  .theme-top p {
    font-size: 12.9667px;
    padding: 0 0 10.9667px 0;
  }

  .theme-top-null {
    width: 300px;
    height: 34px;
  }

  .theme-simbolo#sevenG {
    padding-top: 2.48333px;
  }

  .theme-simbolo img {
    width: 164.667px
  }

  .theme-simbolo#sevenG img {
    width: 144.7px;
  }

  .theme-title {
    font-size: 14.9667px;
    padding: 0 0 0 9.96667px;
    bottom: 12.4667px
  }
}